import React from "react"
import styled from "styled-components/macro"
import { rem } from "polished"
import { color, type } from "core/theme"
import { useAnalytics, useMatchMedia } from "core/hooks"
import { PageTransition } from "core/components"
import { PageContainer, SplitLayout, breakpoint, sizes } from "core/layout"
import {
  Heading,
  AltHeading,
  /*  Image, */ ScrollToTop,
  SocialFooter,
} from "elements"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import PageLayout from "../templates/PageLayout"
import { motion } from "framer-motion"

const Section = styled.section`
  margin: ${rem(40)} 0;

  ${breakpoint.tablet`
    margin: ${rem(120)} 0;
  `};

  ${breakpoint.laptop`
    margin: ${rem(160)} 0;
  `};
`

const ExternalLink = styled.a`
  display: inline-block;
  margin: -0.5em;
  padding: 0.5em;
  text-decoration: none;

  &:hover {
    color: ${color.brand.red};
    text-decoration: underline;
  }
`

const Text = styled.p`
  ${type.body};
  margin: 0;
  margin-bottom: 0.2em;
`

const Spacer = styled.div`
  margin-top: ${rem(72)};
`

const entryVariants = {
  visible: i => ({
    opacity: 1,
    y: 0,
    transition: { type: "spring", damping: 50, delay: i * 0.1 },
  }),
  hidden: { opacity: 0, y: 20 },
}

function FadeInEntry({ custom, children }) {
  return (
    <motion.div
      initial="hidden"
      animate="visible"
      custom={custom}
      variants={entryVariants}
    >
      {children}
    </motion.div>
  )
}

const imageStyle = { maxWidth: rem(536) }

const Card = React.memo(function Card({ fullName, title, images, tel, mail }) {
  return (
    <>
      {images && (
        <Img
          // srcSet={`${images[0]} 536w, ${images[1]} 1072w`}
          // sizes={`(min-width: ${sizes.tablet}px) 50vw, 100vw`}
          fluid={images}
          alt={fullName}
          style={imageStyle}
          halfWidth
        />
      )}
      <FadeInEntry custom={1}>
        <AltHeading>
          {fullName}
          <span>.</span>
        </AltHeading>
      </FadeInEntry>

      <FadeInEntry custom={2}>
        <Text dangerouslySetInnerHTML={{ __html: title }} />
      </FadeInEntry>
      {tel && (
        <FadeInEntry custom={3}>
          <ExternalLink href={`tel:${tel.replace(/\s/g, "")}`}>
            {tel}
          </ExternalLink>
        </FadeInEntry>
      )}
      {mail && (
        <FadeInEntry custom={3}>
          <ExternalLink href={`mailto:${mail}`}>{mail}</ExternalLink>
        </FadeInEntry>
      )}
    </>
  )
})

export default function Contacts({ location, data }) {
  useAnalytics({
    title: "contacts",
    path: location.pathname,
  })

  const matchTabletLayout = useMatchMedia(sizes.tablet)
  const item = data.strapi.contactpage
  
  if (!item) {
    return (
      <PageLayout>
        <PageTransition>
          <PageContainer>
            <Section>
              <Heading>Empty page</Heading>
            </Section>
          </PageContainer>
        </PageTransition>
      </PageLayout>
    )
  }

  if (matchTabletLayout) {
    return (
      <PageLayout>
        <PageTransition>
          <>
            <PageContainer>
              <Section>
                <SplitLayout
                  left={() => (
                    <>
                      <Heading>{item.title}</Heading>
                      <Spacer />
                      {item.contacts.map((user, index) => {
                        if (index % 2 === 1) {
                          return (
                            <div key={user.id}>
                              <Card
                                fullName={user.name_surname}
                                images={
                                  user.portraitImage.imageFile &&
                                  user.portraitImage.imageFile.childImageSharp
                                    .fluid
                                }
                                title={user.position}
                                tel={user.phone}
                                mail={user.email}
                              />
                              <Spacer />
                            </div>
                          )
                        }
                        return null
                      })}
                    </>
                  )}
                  right={() => (
                    <>
                      {item.contacts.map((user, index) =>
                        index % 2 === 0 ? (
                          <div key={user.id}>
                            <Card
                              fullName={user.name_surname}
                              images={
                                user.portraitImage.imageFile &&
                                user.portraitImage.imageFile.childImageSharp
                                  .fluid
                              }
                              title={user.position}
                              tel={user.phone}
                              mail={user.email}
                            />
                            <Spacer />
                          </div>
                        ) : null
                      )}
                    </>
                  )}
                  removeChildMargins
                />
              </Section>

              <Section>
                <SplitLayout
                  left={() => <Heading>{item.titleLocation}</Heading>}
                  right={() => <div />}
                  removeChildMargins
                />
                <Spacer />
                <FadeInEntry>
                  {item.locationImage.imageFile && (
                    <Img
                      fluid={item.locationImage.imageFile.childImageSharp.fluid}
                      alt=""
                    />
                  )}
                </FadeInEntry>
              </Section>

              <ScrollToTop currentId="contacts" />
            </PageContainer>

            <SocialFooter />
          </>
        </PageTransition>
      </PageLayout>
    )
  }

  return (
    <PageLayout>
      <PageTransition>
        <>
          <PageContainer>
            <Section>
              <Heading>{item.title}</Heading>
              {item.contacts.map(user => (
                <div key={user.id}>
                  <Card
                    fullName={user.name_surname}
                    images={
                      user.portraitImage.imageFile &&
                      user.portraitImage.imageFile.childImageSharp.fluid
                    }
                    title={user.position}
                    tel={user.phone}
                    mail={user.email}
                  />
                  <Spacer />
                </div>
              ))}
            </Section>

            <Section>
              <Heading>{item.titleLocation}</Heading>
              <Spacer />
              <FadeInEntry>
                {item.locationImage.imageFile && (
                  <Img
                    fluid={item.locationImage.imageFile.childImageSharp.fluid}
                    alt=""
                  />
                )}
              </FadeInEntry>
            </Section>

            <ScrollToTop currentId="contacts" />
          </PageContainer>

          <SocialFooter />
        </>
      </PageTransition>
    </PageLayout>
  )
}

export const pageQuery = graphql`
  query ContactQuery {
    strapi {
      contactpage {
        id
        title
        contacts {
          id
          name_surname
          position
          phone
          email
          portraitImage {
            url
            imageFile {
              childImageSharp {
                fluid(quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        titleLocation
        locationImage {
          url
          imageFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
`
